import styled from 'styled-components';
import Item from './Links';

const LEGAL_LINKS = [
  {
    en: [
      {
        label: 'Terms and Conditions',
        href: `${process.env.NEXT_PUBLIC_SWORD_WEBSITE_URL}/legal/terms-and-conditions`,
      },
      {
        label: 'Consent to Telehealth Services',
        href: `${process.env.NEXT_PUBLIC_SWORD_WEBSITE_URL}/legal/consent-to-telehealth-services`,
      },
      {
        label: 'HIPAA Notice of Privacy Practices',
        href: `${process.env.NEXT_PUBLIC_SWORD_WEBSITE_URL}/legal/hipaa-notice-of-privacy-practices`,
      },
      {
        label: 'Privacy Statement',
        href: `${process.env.NEXT_PUBLIC_SWORD_WEBSITE_URL}/legal/privacy-statement`,
      },
      {
        label: 'Non-Discrimination',
        href: `${process.env.NEXT_PUBLIC_SWORD_WEBSITE_URL}/legal/notice-of-non-discrimination`,
      },
    ],
    pt: [
      {
        label: 'Termos e Condições',
        href: 'https://cdn.swordhealth.tech/assets/legal_documents/2025/01/TERMOS_E_CONDICOES_DA_SWORD_HEALTH%20_PT.pdf',
      },
      {
        label: 'Consentimento para Serviços de Saúde à Distância',
        href: 'https://cdn.swordhealth.tech/assets/legal_documents/2025/01/CONSENTIMENTO_PARA_SERVICOS_DE_SAUDE_A_DISTANCIA_PT.pdf',
      },
      {
        label: 'Declaração de Privacidade',
        href: 'https://cdn.swordhealth.tech/assets/legal_documents/2025/01/DECLARACAO_DE_PRIVACIDADE_DA_SWORD_HEALTH_PT.pdf',
      },
      {
        label: 'Consentimento para o Processamento de Dados Pessoais',
        href: 'https://cdn.swordhealth.tech/assets/legal_documents/2025/01/consentimento-para-o-processamento-de-dados-pessoais_PT.pdf',
      },
    ],
  },
];

export default function Legal({ locale }) {
  const localizedLinks = LEGAL_LINKS.filter((link) => link[locale]);
  const filteredLinks = localizedLinks[0]?.[locale];
  const cookiePreferencesLabel = locale === 'pt' ? 'Preferências de Cookies' : 'Cookie Preferences';
  return (
    <LinksWrapper>
      {filteredLinks?.map((item) => (
        <Item key={item.href} href={item.href} label={item.label} />
      ))}
      <Item ez-show-preferences="true" label={cookiePreferencesLabel} />
    </LinksWrapper>
  );
}

const LinksWrapper = styled.div`
  display: grid;
  gap: 12px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 32px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    grid-template-columns: 1fr;
  }
`;
