// Components
import Modal from '../Modal';

// Styles
import { GridContainer, CloseButton, IconClose, StyledVideo } from './styles';

const VideoPlayerModal = ({ modalIsOpen, closeModal, videoURL }) => (
  <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
    <GridContainer>
      {modalIsOpen && (
        <CloseButton type="button" onClick={closeModal}>
          <IconClose alt="" src="/icons/circle-close.svg" width="20" height="20" />
        </CloseButton>
      )}
      <StyledVideo controls autoPlay playsInline width="100%">
        <source src={videoURL} />
      </StyledVideo>
    </GridContainer>
  </Modal>
);

export default VideoPlayerModal;
