import { useEffect, useState, useMemo, useContext } from 'react';
import { Container, Heading, SectionHeader } from '@swordhealth/ui-corporate';
import { StyledSection, Content, TimerInner, TimerSegment, Description } from './styles';
import ClientContext from '@/contexts/client.context';
import EnrollButton from '@/app/components/core/EnrollButton';

const TIME_UNITS = ['days', 'hours', 'minutes', 'seconds'];

const formatNumber = (value) => value.toString().padStart(2, '0');
const getTimeDifference = (targetTime) => targetTime - Date.now();

const Countdown = ({ content }) => {
  const { sectionHeader, eventDate, description, buttonLabel } = content;
  const { openModal, client } = useContext(ClientContext);

  const targetTime = useMemo(() => new Date(eventDate).getTime(), [eventDate]);

  const [timeRemaining, setTimeRemaining] = useState(Math.max(getTimeDifference(targetTime), 0));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(Math.max(getTimeDifference(targetTime), 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [targetTime]);

  const formattedValues = useMemo(() => {
    const seconds = Math.floor((timeRemaining / 1000) % 60);
    const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
    const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

    return {
      days: formatNumber(days),
      hours: formatNumber(hours),
      minutes: formatNumber(minutes),
      seconds: formatNumber(seconds),
    };
  }, [timeRemaining]);

  return (
    <StyledSection colored lastColored>
      <Container>
        <Content>
          <SectionHeader
            label={sectionHeader?.upperLabel}
            title={sectionHeader?.title}
            description={sectionHeader?.description}
            titleSize="3xl"
            xAlign="center"
          />
          <TimerInner>
            {TIME_UNITS.map((unit) => (
              <TimerSegment key={unit}>
                <Heading as="div" size="5xl">
                  {formattedValues[unit]}
                </Heading>
                <Heading as="p" size="xl">
                  {unit.charAt(0).toUpperCase() + unit.slice(1)}
                </Heading>
              </TimerSegment>
            ))}
          </TimerInner>
          <Description size="lg">{description}</Description>
          <EnrollButton
            id="countdown_button"
            isActive={client.isActive}
            buttonLocation="countdown"
            openModal={() => openModal({ buttonText: buttonLabel, buttonLocation: 'countdown' })}
            client={client}
            buttonLabel={buttonLabel ?? 'Start Now'}
            product={client?.product}
            redirectToApp={client?.redirectToApp}
          />
        </Content>
      </Container>
    </StyledSection>
  );
};

export default Countdown;
