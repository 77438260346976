import styled from 'styled-components';
import Image from 'next/image';
import { Container } from '@/components/ui/flexbox';

export const GridContainer = styled(Container)`
  display: grid;
`;

export const CloseButton = styled.button`
  height: 48px;
  width: 48px;
  margin-bottom: 10px;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s ease;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  justify-self: end;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 32px;
    height: 32px;
  }
`;

export const IconClose = styled(Image)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 16px;
    height: 16px;
  }
`;

export const StyledVideo = styled.video`
  height: 80vh;
`;
