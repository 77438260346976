import styled from 'styled-components';
import { Paragraph } from '@swordhealth/ui-corporate';
import Section from '@/components/ui/Section';

export const StyledSection = styled(Section)`
  clip-path: circle(farthest-side at 50% -10000px);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    clip-path: circle(farthest-side at 50% -4000px);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    clip-path: circle(farthest-side at 50% -2500px);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  width: 100%;

  h2 {
    text-wrap-style: auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    gap: 32px;
  }
`;

export const TimerInner = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    gap: 80px;
  }
`;

export const TimerSegment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 50%;
  }

  & > div {
    color: ${(props) => props.theme.colors.primary.default};
    font-weight: 500;
    font-feature-settings: 'tnum', 'lnum';
  }

  & > p {
    font-weight: 400;
  }
`;

export const Description = styled(Paragraph)`
  max-width: 640px;
  text-align: center;
`;
