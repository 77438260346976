import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

import Script from 'next/script';
import * as segmentSnippet from '@segment/snippet';

const IS_ENABLED =
  (process.env.NEXT_PUBLIC_ENV === 'production' || process.env.NEXT_PUBLIC_ENV === 'staging') &&
  !!process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

export function renderSegmentScript() {
  if (!IS_ENABLED) return null;

  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: false,
  };

  return (
    <Script id="segment-script" dangerouslySetInnerHTML={{ __html: segmentSnippet.min(opts) }} />
  );
}

export function SegmentProvider({ children }) {
  const searchParams = useSearchParams();
  const pathname = usePathname();

  useEffect(() => {
    if (!IS_ENABLED) return;

    let url = `${pathname}`;
    if (searchParams.toString()) {
      url = `${pathname}?${searchParams}`;
    }

    const fingerprint = searchParams.get('fingerprint');
    const custom_campaign = searchParams.get('custom_campaign');
    const token = searchParams.get('token');
    const fbc = searchParams.get('fbclid'); // facebook id param
    const gclid = searchParams.get('gclid'); // google id param

    global.analytics?.page({
      page: url,
      fbc,
      gclid,
    });

    if (fingerprint) {
      identify(fingerprint, { fingerprint });
    }

    if (custom_campaign) {
      identify(null, { custom_campaign });
    }

    if (fbc) {
      sessionStorage.setItem('fbc', fbc);
    }
    if (gclid) {
      sessionStorage.setItem('gclid', gclid);
    }

    if (token && pathname.substring('campaign/')) {
      const urlDecodedToken = decodeURIComponent(token);
      const decodedToken = Buffer.from(urlDecodedToken, 'base64').toString();
      const splitToken = decodedToken.split('|');
      const clientKey = splitToken[0];

      identify(null, { clientKey: clientKey });
    }
  }, [pathname, searchParams]);

  return <>{children}</>;
}

export function trackEvent(eventName, eventProps) {
  if (!IS_ENABLED) return null;

  const fbc = sessionStorage.getItem('fbc');
  const gclid = sessionStorage.getItem('gclid');

  window?.analytics?.track(eventName, { ...eventProps, fbc, gclid });
}

export function identify(id, identifyProps = {}) {
  if (!IS_ENABLED) return null;

  window?.analytics?.identify(id, identifyProps);
}
